<template>
    <CRow>
        <CCol md="6" xxl="4" xs="12">
            <div class="card">
                <div class="card-header content-center text-white p-0 bg-twitter">
                    <CIcon
                            name="cib-twitter"
                            height="56"
                            class="my-4"
                    />
                </div>
                <slot name="body">
                    <div class="card-body row text-center">
                        <div class="col">
                            <div class="text-value-lg">
                                {{numUsersStr}}
                            </div>
                            <div class="text-uppercase text-muted small">
                                Accounts
                            </div>
                        </div>
                        <div class="c-vr"></div>
                        <div class="col">
                            <div class="text-value-lg">
                                {{numTweetsStr}}
                            </div>
                            <div class="text-uppercase text-muted small">
                                Tweets
                            </div>
                        </div>
                        <div class="c-vr"></div>
                        <div class="col">
                            <div class="text-value-lg">
                                {{numTweetsDeletedStr}}
                            </div>
                            <div class="text-uppercase text-muted small">
                                Deleted Tweets
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
        </CCol>
    </CRow>
</template>

<script>
    // import {CChartLineSimple} from '../charts/index.js'

    export default {
        name: 'WidgetsTwitter',
        data: () => ({
            //twitterStats: {}
        }),
        components: {
            //CChartLineSimple
        },
        computed: {

            numUsers() {
                return this.$store.getters.getTwitterStats.num_users
            },
            numTweets() {
                return this.$store.getters.getTwitterStats.num_tweets
            },
            numTweetsDeleted() {
                return this.$store.getters.getTwitterStats.num_deleted_tweets
            },
            numUsersStr() {
                return this.numUsers.toString()
            },
            numTweetsStr() {
                return this.numTweets.toString()
            },
            numTweetsDeletedStr() {
                return this.numTweetsDeleted.toString()
            },
            deletedToTweetsRatio() {
                return 50
                //return (this.numTweetsDeleted()/this.numTweets())*100
            }
        }
    }
</script>

<style scoped>
    .c-chart-brand {
        position: absolute;
        width: 100%;
        height: 100px;
    }
</style>
